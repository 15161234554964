<template>
  <i ref="el" class="scroll-detector" :style="{ top: top && top + 'px', bottom: bottom && bottom + 'px' }" />
</template>

<script lang="ts" setup>
const { affix, scrolled } = useCzn()

const props = defineProps({
  id: { type: String, default: 'top' },
  top: { type: Number, default: 300 },
  bottom: { type: Number, default: undefined },
})
const emit = defineEmits(['bottom', 'top'])
const { y } = useWindowScrollPosition()

function handleScroll() {
  const height = props.top

  if (y.value < height) {
    affix.value[props.id] = true
    if (!scrolled.value) scrolled.value = true
  } else {
    affix.value[props.id] = false
  }
}

const el = ref<HTMLElement | null>(null)

const observer = ref<IntersectionObserver | null>(null)

onMounted(() => {
  if (!(props.id in affix))
    affix.value[props.id] = false

  if ('IntersectionObserver' in window) {
    observer.value = new IntersectionObserver((entries) => {
      if (!observer.value) return
      if (entries[0].boundingClientRect.bottom < 0) {
        affix.value[props.id] = true
        if (!scrolled.value) scrolled.value = true
      } else {
        affix.value[props.id] = false
      }
    })
    if (!el.value) return
    observer.value.observe(el.value)
  } else {
    window.addEventListener('scroll', throttle(handleScroll, 50), {
      passive: true
    })
    handleScroll()
  }
})

onUnmounted(() => {
  if (!('IntersectionObserver' in window))
    window.removeEventListener('scroll', handleScroll)
})
</script>

<style lang="scss">
.scroll-detector {
  position: absolute;
  left: 50vw;

  width: 1px;
  height: 1px;
}
</style>
